export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Ondemand",
    route: "",
    icon: "TrendingUpIcon",
    children: [
      {
        title: "Create Order",
        route: "on-demand-delivery",
        icon: "SendIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "on-demand-orders",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "Distributor",
    route: "",
    icon: "ShoppingBagIcon",
    children: [
      {
        title: "Create Order",
        route: "distributor-bulk-delivery",
        icon: "BoxIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "Distributor-Bulk-orders",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "DHL",
    route: "",
    icon: "TruckIcon",
    children: [
      {
        title: "Create Order",
        route: "DHL-delivery",
        icon: "BoxIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "DHL-orders",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "Track & Trace",
    route: "track-and-trace",
    icon: "MapIcon",
  },
  {
    title: "Phonebook",
    route: "phonebook",
    icon: "PhoneIcon",
  },
  {
    title: "Log Points",
    route: "log-points",
    icon: "SearchIcon",
    children: [
      {
        title: "Log Points",
        route: "log-points",
        icon: "SearchIcon",
        action: "manage",
      },
      {
        title: "Log Point Details",
        route: "log-point-details",
        icon: "ArrowRightIcon",
        action: "manage",
      },
    ],
  },
];
